import { datadogLogs } from '@datadog/browser-logs'
import {useEffect} from "react";
import {isProduction} from "~/helpers/environment";
import {useUser} from "@clerk/clerk-react";

if (isProduction) {
  if(process.env.NEXT_PUBLIC_DATADOG_TOKEN) {
    // TODO: create logger and use that instead of console
    datadogLogs.init({
      clientToken: process.env.NEXT_PUBLIC_DATADOG_TOKEN,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: ['log', 'info', 'warn', 'error'],
      service: 'frontend',
      env: process.env.NEXT_PUBLIC_GG_NODE_ENV,
      version: process.env.NEXT_PUBLIC_APP_VERSION,
      beforeSend: (log) => {
        // remove email from view url
        log.view.url = log.view.url.replace(/authToken=[^&]*/, "authToken=REDACTED")
      },
    })
  }
}

export const useDatadog = () => {
  const { user } = useUser()

  useEffect(() => {
    if(user) {
      const ddUser = {
        id: user.externalId,
        username: user.publicMetadata.username
      }
      datadogLogs.setUser(ddUser)
    }
  }, [user]);
}
