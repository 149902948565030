import React, { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { connectToChild } from 'penpal'
import { openLink } from '~/helpers'
import Loading from '~/components/Loading'
import { webrtc } from '~/providers/MfdProvider'
import { PeerEvents } from '@gameglass/webrtc'
import {useSelector} from "react-redux";

export let penpalChildConnection = null

let edState = undefined

const ShardIframe = () => {
  const iframeRef = useRef()
  const router = useRouter()
  const { shardId, sourceShardId } = router.query
  const [loading, setLoading] = useState(true)
  const token = useSelector(state => {
    return state.authenticationReducer.token
  })
  const showIframe = router.route === '/shardlibrary/[collectionId]/[shardId]' && !!token
  const [iframeSource, setIframeSource] = useState()

  useEffect(() => {
    const sendEDData = async ({ data }) => {
      if (data?.type === 'edState' && data?.payload) {
        try {
          edState = {
            ...(edState || {}),
            ...data.payload
          }
          const child = await penpalChildConnection?.promise
          await child?.edState(data.payload)
        } catch (error) {
          console.warn(error)
        }
      }
    }

    webrtc.on(PeerEvents.data, sendEDData)

    return () => {
      webrtc.off(PeerEvents.data, sendEDData)
    }
  }, [token])

  useEffect(() => {
    // Only run it when all information is available, but only once at first load.
    if(!shardId || !token || iframeSource) return

    setLoading(true)

    const urlSearchParams = new URLSearchParams({
      authToken: token,
      t: Date.now()
    })

    if (shardId) urlSearchParams.set('shardId', shardId)
    if (sourceShardId) urlSearchParams.set('sourceShardId', sourceShardId)

    setIframeSource(`${process.env.NEXT_PUBLIC_FORGE_URL}/render?${urlSearchParams}`)
  }, [token, shardId, sourceShardId])

  useEffect(() => {
    // Prevent penpal from being initialized more than once
    if (!iframeRef.current || !iframeSource || !router || penpalChildConnection) return

    try {
      penpalChildConnection = connectToChild({
        iframe: iframeRef.current,
        methods: {
          clientMounted: (isMounted) => {
            setLoading(!isMounted)
            if(isMounted && edState) {
              penpalChildConnection?.promise.then((connection) => connection.edState(edState))
            }
          },
          goToForge: (where) => {
            router.push(
                where === 'collection_shard_list' ?
                    '/shardlibrary' :
                    `/shardlibrary/${router.query.collectionId || 0}`
            )
          },
          goToURL: (url, useDefaultBrowser) => openLink(url, useDefaultBrowser),
          rtcSend: (data) => {
            webrtc?.send?.(data)
          },
          rtcSendWhenConnected: (data) => {
            webrtc?.sendWhenConnected?.(data)
          },
          changeRoute: ({
                          collectionId,
                          shardId,
                          sourceShardId,
                        }) => {
            const path = `/shardlibrary/${collectionId}/${shardId}${sourceShardId ? `?sourceShardId=${sourceShardId}` : ''}`

            if (router && path !== router.asPath) {
              router.push(path)
            }
          }
        }
      })

    } catch (error) {
      console.warn(error);
    }
  }, [iframeRef.current, iframeSource, router])

  useEffect(() => {
    // When iframe is not shown, reset all connections
    if (!showIframe && iframeSource) {
      setIframeSource(null)
      penpalChildConnection?.destroy()
      penpalChildConnection = null
    }
  }, [showIframe])

  return (
    <>
      {showIframe && loading && <Loading fixed message="Loading shard..." />}
      <iframe
        ref={iframeRef}
        css={`
         display: ${showIframe ? 'block' : 'none'};
          width: 100vw;
         height: 100vh;
        `}
        src={iframeSource}
      />
    </>
  )
}

const ShardIframeComponent = () => {
  return (
    <ShardIframe />
  )
}

export default ShardIframeComponent
