import InputMask from '~/components/common/InputMask'
import ErrorPage from '~/pages/_error'
import Announcement from '~/components/common/Announcement'
import useGlobalError from '~/hooks/useGlobalError'
import useAnnouncements from '~/hooks/useAnnouncements'
import useDevice from '~/hooks/useDevice'
import useInternetConnection from '~/hooks/useInternetConnection'
import React, {useEffect, useMemo} from 'react'
import ShardIframe from './ShardIframe'
import { useAnalytics } from "~/hooks/useAnalytics";
import { useFeatureFlags } from "~/hooks/useFeatureFlags";
import { useDatadog } from "~/utils/datadog";
import { SignedIn, SignedOut} from "@clerk/clerk-react";
import {AuthIntermediator} from "~/components/AuthIntermediator";
import {useRouter} from "next/router";
import {usePosthogIdentify} from "~/utils/posthog";
import Game from "~/components/Game";

const Renderer = (props) => {
  useDevice()
  useInternetConnection()
  useAnalytics()
  useFeatureFlags()
  useDatadog()
  usePosthogIdentify()

  const router = useRouter()
  const { Component, pageProps } = props
  const [error] = useGlobalError(props.error || props.err)
  const { announcements } = useAnnouncements()
  const errorAnnouncement = useMemo(() => {
    return announcements.find(a => !a.modal)
  }, [announcements])

  const modalAnnouncements = useMemo(() => {
    return announcements.filter(a => a.modal)
  }, [announcements])

  useEffect(() => {
    if(window.originalPostMessage) {
      console.warn("Windows Store App")
    }
  }, []);

  const RedirectToSignIn = () => {
    useEffect(() => {
      const path = router.asPath;
      router?.push(`/login${path.startsWith("/shardlibrary") ? `?redirect_url=${encodeURIComponent(path)}` : ''}`)
    }, []);
  }

  return (
    <div className='THEME'>
      {(error || errorAnnouncement) ? (
        <ErrorPage
          err={error}
          statusCode={props.statusCode}
          {...(errorAnnouncement || {})}
        />
      ) : (
        <>
          <SignedIn>
            <AuthIntermediator>
              {router.asPath.startsWith("/shardlibrary") ? <Game>
                <Component {...pageProps} />
                <ShardIframe />
              </Game> : <Component {...pageProps} />
              }
            </AuthIntermediator>
          </SignedIn>
          <SignedOut>
            {/\/(login|sso-.*)/.test(router.asPath) ? <Component {...pageProps} /> : <RedirectToSignIn/>}
          </SignedOut>
          {!props.statusCode && (
            modalAnnouncements.map((announcement) => (
              <Announcement
                key={announcement.id}
                {...announcement}
              />
            ))
          )}
        </>
      )}
      <InputMask />
    </div>
  )
}

export default Renderer
