import { useMemo } from 'react'
import styled from 'styled-components'
import { COLORS } from '../utils'

const SVG = styled.svg`
  max-width: 90%;
  width: 100px;
  margin-left: auto;
  margin-right: auto; 
`

const PulsingLogo = ({ type, shadow = true, pulse = true }) => {
  const colors = useMemo(() => {
    return COLORS[type] || COLORS.default
  }, [type])

  return (
      <SVG
          viewBox="0 0 83.999992 95.063316"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
        <path
            d="m 0.8585,69.981918 v -44.44826 c 0,-0.851133 0.4526054,-1.638119 1.1883715,-2.066098 L 40.356757,1.1820526 c 0.743414,-0.43239364 1.66187,-0.43209927 2.405096,7.884e-4 L 54.210038,7.8517319 17.268866,29.521272 c -2.251167,1.320518 -3.634091,3.735053 -3.634091,6.345016 V 60.68561 c 0,1.409333 1.524115,2.291584 2.746237,1.589462 l 11.030704,-6.336873 c 0.07738,-0.04446 0.173961,0.01155 0.173961,0.100771 v 12.878401 c 0,0.848308 -0.449565,1.633034 -1.181303,2.062095 l -11.624406,6.8151 c -0.730372,0.428142 -1.632728,0.43806 -2.372233,0.02601 L 2.0854308,72.070119 C 1.3279812,71.648119 0.8585,70.849069 0.8585,69.981918 Z M 36.344428,36.376679 68.311053,17.460553 c 0.745274,-0.441042 1.670698,-0.444457 2.41922,-0.009 l 11.563399,6.726788 -30.693104,17.88287 c -2.261855,1.317786 -3.652944,3.738292 -3.652944,6.356007 v 24.66332 c 0,1.412965 1.531104,2.294715 2.753226,1.585637 l 15.126814,-8.777629 c 2.263903,-1.313667 3.659302,-3.731331 3.664104,-6.348753 l 0.01535,-8.210087 c 0.0012,-0.850358 0.454765,-1.635764 1.190139,-2.062665 l 11.58848,-6.728265 -0.02373,27.216484 c -7.37e-4,0.854287 -0.458154,1.643337 -1.197965,2.069441 L 42.732848,93.886666 c -0.72781,0.418998 -1.62204,0.424885 -2.355642,0.01673 l -3.97887,-2.21746 C 35.640891,91.264068 35.171313,90.465383 35.171313,89.597973 V 38.433775 c 0,-0.844871 0.44594,-1.626844 1.173064,-2.057082 z"
            fill={`url(#paint-${type})`}
            stroke={`url(#paint-${type})`}
            strokeWidth="1.717"
        />
        <defs>
          <linearGradient id={`paint-${type}`} x1="71.399" y1="192.983" x2="133.428" y2="56.251"
                          gradientUnits="userSpaceOnUse">
            <stop stopColor={colors.top}/>
            <stop offset="1" stopColor={colors.bottom}/>
          </linearGradient>
        </defs>
      </SVG>
  )
}

export default PulsingLogo